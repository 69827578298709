import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import '../assets/styles/base/standard-page.scss';

import Helmet from 'react-helmet';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AnouncementBar from '../components/home/announcement-bar';

const Blog = () => {
  const data = useStaticQuery(graphql`
    query {
      allBlog {
        edges {
          node {
            title,
            slug,
            excerpt
          }
        }
      }
    }
  `);
  const schema = () => {
    return {
      "@context": "http://schema.org/",
      "@type": "Blog",
      "Description": "Advice, tips and guides to help you find the best repair solution for your car, whether it was smashed, scratched or dented or if you’re using the your services of your local panel beater.",
      "Publisher": "DingGo",
      "URL": "https://dinggo.com.au/blog",
      "image": "https://dinggo.com.au/img/DingGo-Logo-White.png"
    };
  };
  return (
    <Layout wrapperClass="standard-page" showSiteIndex={true} hideHeaderFooter={true}>
      <SEO title="DingGo Blog | Tips & tricks for car owners" description="Advice, tips and guides to help you find the best repair solution for your car, whether it was smashed, scratched or dented or if you’re using the your services of your local panel beater." />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schema())}
        </script>
      </Helmet>
      <AnouncementBar />
      <div className="hero-banner">
        <div className="container">
          <h1 className="mb-4">Blog</h1>
          <p>
            Helping provide the information you need to get the best repairs for your car.
          </p>
        </div>
      </div>
      <nav className="breadcrumb" aria-label="Breadcrumb">
        <ol className="breadcrumb__list">
          <li className="breadcrumb__item">
            <Link className="breadcrumb__link" href="/">DingGo</Link>
            <span className="breadcrumb__separator" aria-hidden="true"> / </span>
          </li>
          <li className="breadcrumb__item">
            <span className="breadcrumb__link breadcrumb__link__active">
              Blog
            </span>
          </li>
        </ol>
      </nav>
      <div className="py-7 bg-light">
        <div className="container main-wrapper">
          <div className="row mb-4">
            {data.allBlog.edges.map(({ node }) => (
              <div className="col-6 mb-4" key={node.slug}>
                <div className="card shadow">
                  <div className="card-body">
                    <h5 className="card-title">{node.title}</h5>

                    <p className="card-text">{node.excerpt}</p>
                    <Link to={`/blog/${node.slug}`} className="">Read more</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
